import { Meta, Title } from '@solidjs/meta';
import { Button, Container, ContentCarousel, Heading, HorizontalRule, Link, Page, Picture, Section } from '@troon/ui';
import { YoutubeVideo } from '@troon/video/youtube';
import { For, Show, Suspense } from 'solid-js';
import { createAsync } from '@solidjs/router';
import dayjs from '@troon/dayjs';
import { useTrackEvent } from '@troon/analytics';
import { Hero } from '../../../components/hero/photo';
import { getConfigValue } from '../../../modules/config';
import { PictureSection } from '../../../components/layouts/picture-section';
import { VideoSection } from '../../../components/layouts/video-section';
import { createContentfulListRequest } from '../../content/[model]/_client';
import { RichText } from '../../../components/rich-text';
import type { RouteDefinition } from '@solidjs/router';
import type { Component } from 'solid-js';

export default function ShotScopeHub() {
	const track = useTrackEvent();
	const articles = createAsync(async () => getArticles(), { deferStream: true });

	return (
		<>
			<Title>Shot Scope | Troon</Title>
			<Meta
				name="description"
				content="Welcome to the Troon Game Performance HUB, powered by Shot Scope. The Hub is your destination for expert insight on game performance from tee-to-green."
			/>

			<Hero src={`${getConfigValue('IMAGE_HOST')}/web/hero/shot-scope-hero.png`}>
				<Heading as="h1">Troon + Shot Scope</Heading>
			</Hero>

			<Page>
				<Container>
					<PictureSection src={`${getConfigValue('IMAGE_HOST')}/web/photo/shot-scope/shot-scope-promo.png`}>
						<Heading as="h2">Welcome to the Troon Game Performance Hub.</Heading>
						<p>
							<span>Powered by</span>{' '}
							<Picture
								src="https://images.ctfassets.net/rdsy7xf5c8dt/5fvpbUQovzlRd0Xs1ADtCy/0257df3a3db4552409c3cff5c197b0a5/shot-scope.png"
								width={230}
								height={24}
								sizes="16rem"
								crop="center"
								mode="contain"
								alt="Shot Scope"
								class="inline"
							/>
						</p>
						<p>
							The Hub is your destination for expert insight on game performance from tee-to-green. Backed by Shot Scope
							data and analysis that tracks over 100 statistics, you can gain valuable information on how to improve
							your game from anywhere on the course.
						</p>
					</PictureSection>
				</Container>

				<Container>
					<HorizontalRule />
				</Container>

				<Container>
					<Suspense>
						<Show when={articles()?.items}>
							{(articles) => (
								<ContentCarousel containerClass="bg-neutral-100 rounded">
									<For each={articles()}>
										{(article) => (
											<ContentCarousel.Item>
												<div class="p-8 md:p-16">
													<PictureSection reverse src={article.fields.heroImage?.fields.file?.url}>
														<Heading as="h3">{article.fields.title}</Heading>
														<div>
															<RichText document={article.fields.summary} />
															<time class="text-sm text-neutral-800">
																{dayjs(article.fields.publishDate, 'America/Phoenix').format('MMMM D, YYYY')}
															</time>
														</div>
														<Button
															as={Link}
															href={`/shot-scope/articles/${article.fields.slug}`}
															class="size-fit shrink grow-0"
														>
															Read more<span class="sr-only"> on {article.fields.title}</span>
														</Button>
													</PictureSection>
												</div>
											</ContentCarousel.Item>
										)}
									</For>
								</ContentCarousel>
							)}
						</Show>
					</Suspense>
				</Container>

				<Section appearance="primary" class="overflow-hidden">
					<Container class="relative flex flex-row gap-8">
						<Section class="max-w-lg">
							<Heading as="h2">Download the Shot Scope and Troon E-Book!</Heading>
							<Button
								as={Link}
								class="size-fit grow-0"
								download
								onClick={() => {
									track('download', {
										'file download': `${getConfigValue('CDN')}/web/photo/shot-scope/Data_with_Danny_Maude.pdf`,
									});
								}}
								target="_blank"
								href={`${getConfigValue('CDN')}/web/photo/shot-scope/Data_with_Danny_Maude.pdf`}
							>
								Download now
							</Button>
						</Section>
						<Picture
							src={`${getConfigValue('IMAGE_HOST')}/web/photo/shot-scope/ebook.png`}
							alt=""
							width={480}
							height={340}
							sizes="50vw"
							class="relative -bottom-24 right-0 -mt-24 hidden w-full rotate-6 lg:block"
						/>
					</Container>
				</Section>
			</Page>

			<Container>
				<Page>
					<Section>
						<Heading as="h2">Shot Scope Quick Tips</Heading>
						<Section class="grid grid-cols-1 gap-8 md:grid-cols-2">
							<Section>
								<YoutubeVideo
									code="uHZT2nR6X-M"
									poster={`${getConfigValue('IMAGE_HOST')}/web/photo/shot-scope/shot-scope-poster-never-give-up.png`}
								/>
								<Heading as="h3" size="h5">
									Shot Scope data tells me: "Never up, never in"
								</Heading>
							</Section>
							<Section>
								<YoutubeVideo
									code="BBYzD_afsqw"
									poster={`${getConfigValue('IMAGE_HOST')}/web/photo/shot-scope/shot-scope-poster-not-safer.png`}
								/>
								<Heading as="h3" size="h5">
									Shot Scope data tells me: "Not safer, just shorter"
								</Heading>
							</Section>
						</Section>
					</Section>

					<HorizontalRule />

					<Section class="grid grid-cols-2 gap-4 md:grid-cols-3 md:gap-6">
						<For each={highlights}>
							{(highlight) => (
								<div class="flex flex-col gap-4">
									<Picture
										src={`${getConfigValue('IMAGE_HOST')}/web/photo/shot-scope/${highlight.src}`}
										alt=""
										width={400}
										height={400}
										sizes="(min-width: 768px) 33vw, 50vw"
										class="aspect-square w-full rounded"
									/>
									<Heading as="h3" size="h5">
										{highlight.title}
									</Heading>
									<highlight.content />
								</div>
							)}
						</For>
					</Section>

					<HorizontalRule />

					<VideoSection
						type="youtube"
						code="lASUcY_FCWg"
						poster={`${getConfigValue('IMAGE_HOST')}/web/photo/shot-scope/shot-scope-poster-product-highlight.png`}
					>
						<Heading as="h2">The best value rangefinder of {new Date().getFullYear()} - Shot Scope PRO L2</Heading>
						<p>
							The Shot Scope PRO L2 is a compact and stylish rangefinder providing the golfer with fast and accurate
							distances on the golf course. Never guess again with adaptive slope technology correctly adjusting your
							distance, taking the up and down hills into account to allow for better club selection. Quickly flick the
							switch to turn slope off, making the PRO L2 legal in tournaments.
						</p>
						<Button
							as={Link}
							href="https://shotscope.com/products/golf-rangefinders/pro-l2/?utm_source=Troon&utm_medium=Hub&utm_campaign=Troon-PRO-L2"
							class="size-fit shrink grow-0"
						>
							Buy Now
						</Button>
					</VideoSection>
				</Page>
			</Container>
		</>
	);
}

export const route = { info: { nav: { hero: true } } } satisfies RouteDefinition;

const highlights: Array<{ src: string; title: string; content: Component }> = [
	{
		src: 'highlight-0.jpeg',
		title: 'Do You Drive It Like A PGA Tour Pro',
		content: () => <p>Driving accuracy of a Top 100 PGA Tour Pro is 61%. Scratch golfers hit 49% of fairways.</p>,
	},
	{
		src: 'highlight-1.jpeg',
		title: 'How Often Do You Miss Short?',
		content: () => (
			<>
				<p>From 80 yards in the fairway, amateurs still miss a lot of shots short!</p>
				<ul>
					<li>20hcp - 38%</li>
					<li>15hcp - 27%</li>
					<li>10hcp - 25%</li>
					<li>5hcp - 19%</li>
				</ul>
			</>
		),
	},
	{
		src: 'highlight-2.jpeg',
		title: 'How Often Do You 3 Putt',
		content: () => (
			<ul>
				<li>20hcp - Every 8.2 holes</li>
				<li>15hcp - Every 10.4 holes</li>
				<li>10hcp - Every 14.7 holes</li>
				<li>5hcp - Every 20.9 holes</li>
			</ul>
		),
	},
	{
		src: 'highlight-3.jpeg',
		title: 'Par 3 Scoring Average',
		content: () => (
			<>
				<p>Par 3's are tougher than you think</p>
				<ul>
					<li>15hcp - 3.83</li>
					<li>10hcp - 3.6</li>
					<li>5hcp - 3.4</li>
				</ul>
			</>
		),
	},
	{
		src: 'highlight-4.jpeg',
		title: 'LPGA vs. Female Amateur',
		content: () => (
			<>
				<p>
					The number one ranked LPGA Tour player in Fairways in Regulation, has hit 88% of fairways so far this season.
				</p>
				<p>34% more than a low single figures female amateur.</p>
			</>
		),
	},
	{
		src: 'highlight-5.png',
		title: 'Make less double bogeys!',
		content: () => (
			<p>
				When we compare a scratch golfer and 25hcp golfers scoring, the scratch player has 2 more birdies per round, and
				9 less doubles - don't focus on making birdies, instead, focus on avoiding doubles.
			</p>
		),
	},
];

const getArticles = createContentfulListRequest('articles', { 'fields.articleType': 'Shot Scope Hub' });
